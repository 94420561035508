import { render, staticRenderFns } from "./ProjectDetails.vue?vue&type=template&id=5c9e0c2a&scoped=true&"
import script from "./ProjectDetails.js?vue&type=script&lang=js&"
export * from "./ProjectDetails.js?vue&type=script&lang=js&"
import style0 from "./ProjectDetails.scss?vue&type=style&index=0&id=5c9e0c2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c9e0c2a",
  null
  
)

export default component.exports